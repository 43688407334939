@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

#shuts-widget-chat {
  .shuts-chat-box {
    background: var(--chatBoxBackground);
    height: 100%;
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 15px;
    overflow: hidden;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
  }

  .shuts-clear-button {
    border: 0;
    outline: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 100px;
    display: block;
    position: absolute;
    top: 22px;
    font-size: 13px;
    right: 14px;
    transition: all 0.3s ease-in-out;
    background: var(--buttonBg);
    color: var(--buttonText);
    &:hover {
      opacity: 0.7;
    }
  }

  .shuts-clear-modal {
    background: var(--chatBoxBackground);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    flex-direction: column;
    left: 0;
    top: 0;
    z-index: 1;

    p {
      font-size: 18px;
      font-weight: 600;
    }

    &__buttons {
      display: flex;
      gap: 10px;
      margin-top: 0;

      button {
        background: var(--buttonBg);
        color: var(--buttonText);
        outline: none;
        padding: 5px 10px;
        border-radius: 5px;
        cursor: pointer;
        width: 100px;
        border: 0;
        display: block;
        font-size: 13px;
        transition: all 0.3s ease-in-out;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}
