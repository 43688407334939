#shuts-widget-chat .shuts-chat {
  &-msg-list {
    margin: 45px 0 25px;
    transition: all 1s ease-in-out;
    height: 90%;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    gap: 25px;
    padding: 0 15px;
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: var(--scrollbarTrack);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--scrollbarThumb);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--scrollbarHover);
    }
  }

  &-title {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin: auto auto 50px;
    transition: all 0.5s ease-in-out;
    opacity: 1;
    top: 0px;
    position: relative;
    font-family: "Rubik", sans-serif;
    color: var(--chatTitle);

    &--error {
      margin: 10px 0;
      height: 63px;
      overflow: hidden;
    }

    &--hide {
      opacity: 0;
      top: -60px;
      pointer-events: none;
      height: 0;
      margin: 0;

      &-error {
        top: 0;
      }
    }
    svg {
      display: block;
      margin: 0 auto 10px;
      * {
        fill: var(--chatTitle);
      }
    }
  }

  &-msg {
    a {
      color: var(--messageLinks);
    }

    p,
    code {
      font-size: 16px;
      font-weight: 400;
      margin: 0;
      padding-bottom: 15px;
      font-family: "Karla", sans-serif;
      display: inline-block;
    }

    // target first child
    &-row--first {
      margin-top: auto;
    }

    &-row--bot {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      svg {
        margin-right: 10px;
        path {
          fill: var(--botIcon);
          fill-opacity: 1;
        }
        circle {
          fill: var(--botIconBg);
          fill-opacity: 1;
        }
      }
    }

    &--user {
      background: var(--messageUserBg);
      color: var(--messageUserText);
      border-radius: 12px 12px 0 12px;
      padding: 15px 15px 0;
      display: block;
      width: 80%;
      margin-left: auto;
    }

    &--bot {
      background: var(--messageBotBg);
      color: var(--messageBotText);
      border-radius: 12px 12px 12px 0;
      padding: 15px 15px 0;
      display: block;
      width: 80%;
      margin-right: auto;
    }
  }
}

.c-three-dots-loader {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: -20px 24px 20px;
  animation-fill-mode: both;
  animation: three-dots-loader-animation 2s infinite ease-in-out;
  animation-delay: -0.16s;
  color: var(--dotLoader);

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    animation: three-dots-loader-animation 2s infinite ease-in-out;
    border-radius: 50%;
  }
  &:before {
    left: -24px;
    animation-delay: -0.32s;
  }
  &:after {
    left: 24px;
  }
}

@keyframes three-dots-loader-animation {
  0%,
  80%,
  100% {
    box-shadow: 0 20px 0 -24px;
  }
  40% {
    box-shadow: 0 20px 0 0;
  }
}
