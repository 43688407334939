#shuts-widget-chat {
  .consent-form {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
    position: absolute;
    background: var(--chatBoxBackground);
    z-index: 10;
    left: 0;

    &--show {
      opacity: 1;
      pointer-events: all;
    }

    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      gap: 15px;
    }

    &__group {
      width: 100%;
      max-width: 300px;
    }

    &__label {
      display: block;
      margin: 10px 0;
    }

    &__error {
      display: block;
      color: #e90d0d;
      font-size: 16px;
      margin: 5px 0 0;
    }

    &__submit {
      background: var(--buttonBg);
      color: var(--buttonText);
      border: none;
      width: 100%;
      max-width: 300px;
      height: 48px;
      text-align: center;
      line-height: 48px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: none;
      outline: none;
      margin: 0 0 0 !important;
      cursor: pointer;
    }

    &__input[type="text"],
    &__input[type="email"] {
      width: 100%;
      height: 48px;
      margin-right: 15px;
      border: none;
      border-radius: 10px;
      padding: 0 15px;
      font-size: 16px;
      outline: none;
      box-sizing: border-box;
      background-color: var(--inputBg);
      color: var(--inputText);
    }

    &__group--checkbox {
      display: block;
      input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
      }

      label {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
      }

      label:before {
        content: "";
        background-color: transparent;
        border: 2px solid rgb(50, 57, 60);
        box-shadow: 0 1px 2px rgba(50, 57, 60, 0.05),
          inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
        padding: 10px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 10px;
      }

      input:checked + label:after {
        content: "";
        display: block;
        position: absolute;
        top: 9px;
        left: 8px;
        width: 6px;
        height: 14px;
        border: solid #32393c;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
    .disclaimer {
      font-size: smaller;
      text-align: center;
    }
  }
}
